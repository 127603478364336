import { Heading, Text } from "../../components";
import NodeJsInfoRow from "../../components/NodeJsInfoRow";
import React, { Suspense } from "react";

// Skills list with categories and skills
const skillsList = [
  {
    category: "Programming Languages",
    skills: ["JavaScript", "C", "Lua", "C++", "Rust"],
  },
  {
    category: "Backend Technologies",
    skills: ["Node.js", "Express.js", "MongoDB"],
  },
  {
    category: "Frontend Technologies",
    skills: ["React", "Redux", "Tailwind CSS", "GraphQL"],
  },
  {
    category: "Other Tools & Libraries",
    skills: ["OpenCV", "Discord.js", "Socket.io"],
  },
];

export default function AboutMeSection() {
  return (
    <>
      {/* about me section */}
      <div className="mt-20 flex flex-col items-center gap-[98px] px-14 lg:gap-[98px] md:gap-[73px] md:px-5 sm:gap-[49px] sm:px-4">
        <div className="container-xs">
          <div className="flex justify-center px-14 md:px-5 sm:px-4">
            <Heading
              size="headinglg"
              as="h1"
              className="text-center text-[56px] font-bold leading-[89px] text-blue-500 lg:text-[47px] md:text-[36px] sm:text-[30px] whitespace-nowrap"
            >
              Hello! I'm Matin
            </Heading>
          </div>
          <div>
            <Text
              size="textmd"
              as="p"
              className="text-center text-[19.2px] font-normal leading-[30px] text-gray-400 lg:text-[16px]"
            >
              <>
                With over six years of programming experience, I specialize in backend development, creating APIs, full-stack applications, Discord bots, video-games, frameworks, plugins, and libraries. I'm proficient in JavaScript, C, Lua, as well as languages like C++ and Rust. I'm always eager to collaborate on new projects and learn new technologies. If you're interested in working together or have any questions, feel free to reach out to me via email!
              </>
            </Text>
          </div>
        </div>
        <div className="container-xs flex flex-col gap-5">
          <div className="flex justify-center px-14 md:px-5 sm:px-4">
            <Heading
              as="h2"
              className="w-[10%] text-center text-[44.8px] font-bold leading-[71px] text-blue-500 lg:text-[37px] md:text-[28px] sm:text-[22px]"
            >
              Skills
            </Heading>
          </div>
          <div className="flex gap-[50px] px-6 py-5 md:flex-col sm:px-4">
            <Suspense fallback={<div>Loading feed...</div>}>
              {skillsList.map((skillCategory, index) => (
                <NodeJsInfoRow  {...skillCategory}
                  nodeJsTitle={skillCategory.category}
                  javascriptDescription={skillCategory.skills.join(", ")}
                  key={`skillsList-${index}`}
                />
              ))}
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}
