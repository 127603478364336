import { Text, Heading } from "./..";
import React from "react";

// Example list of projects
const projectList = [
  {
    name: "PricePal",
    description: "A price comparison app built using React Native and MongoDB.",
    link: "https://github.com/decompi/pricepal",
  },
  {
    name: "Syntax Fixer",
    description: "A Roblox plugin to automatically fix syntax errors.",
    link: "https://github.com/decompi/syntax-fixer",
  },
  {
    name: "Pixel Stream Player",
    description: "A video player for Roblox using Node.js.",
    link: "https://github.com/decompi/pixel-stream-player",
  },
  {
    name: "finger-count",
    description: "A C# app using OpenCV to track fingers via camera masking.",
    link: "https://github.com/decompi/finger-count",
  },
  {
    name: "Fortnite Building System",
    description: "A Lua-based building system inspired by Fortnite, designed for Roblox.",
    link: "https://github.com/decompi/FortniteBuildingSystem"
  }  
];

export default function UserInterfaceComponent({
  headingText = "My Projects",
  descriptionText = "Here are some of my programming projects. Click to view the repositories on GitHub.",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-start gap-4 p-6`}
      style={{
        background: "rgb(35, 35, 35)",
        padding: "25px",
        borderRadius: "15px",
        boxShadow: "rgba(0, 0, 0, 0.3) 0px 10px 20px",
        transition: "transform 0.3s, background 0.3s, box-shadow 0.3s",
        boxSizing: "border-box",
        margin: "0px",
      }}
    >
      <div className="flex self-stretch">
        <Heading
          size="headings"
          as="h3"
          className="text-[28.8px] font-bold text-white-a700 md:text-[26px] sm:text-[24px]"
          style={{
            margin: "0px",
            padding: "0px",
            fontSize: "1.8rem",
            marginBottom: "15px",
            boxSizing: "border-box",
          }}
        >
          {headingText}
        </Heading>
      </div>
      <div className="flex self-stretch">
        <Text
          as="p"
          className="text-[16px] font-normal text-gray-400"
          style={{
            margin: "0px",
            padding: "0px",
            color: "rgb(187, 187, 187)",
            marginBottom: "20px",
            boxSizing: "border-box",
          }}
        >
          {descriptionText}
        </Text>
      </div>

      {/* Project List */}
      <div className="flex flex-wrap gap-4">
        {projectList.map((project, index) => (
          <div
            key={index}
            className="flex flex-col items-start gap-4 p-6"
            style={{
              background: "rgb(35, 35, 35)",
              padding: "25px",
              borderRadius: "15px",
              boxShadow: "rgba(0, 0, 0, 0.3) 0px 10px 20px",
              transition: "transform 0.3s, background 0.3s, box-shadow 0.3s",
              boxSizing: "border-box",
              width: "100%",
              margin: "0px",
            }}
          >
            <div className="flex self-stretch">
              <Heading
                size="headings"
                as="h3"
                className="text-[28.8px] font-bold text-white-a700 md:text-[26px] sm:text-[24px]"
                style={{
                  margin: "0px",
                  padding: "0px",
                  fontSize: "1.8rem",
                  marginBottom: "15px",
                  boxSizing: "border-box",
                }}
              >
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  {project.name}
                </a>
              </Heading>
            </div>
            <div className="flex self-stretch">
              <Text
                as="p"
                className="text-[16px] font-normal text-gray-400"
                style={{
                  margin: "0px",
                  padding: "0px",
                  color: "rgb(187, 187, 187)",
                  marginBottom: "20px",
                  boxSizing: "border-box",
                }}
              >
                {project.description}
              </Text>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
