import { Text, Heading } from "./.."; // Removed Img import
import React from "react";

export default function NodeJsInfoRow({
  nodeJsTitle = "Node.js",
  javascriptDescription = (
    <>
      Main Javascript Runtime
      <br />
      Environment.
    </>
  ),
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-start w-[24%] md:w-full gap-4 bg-gray-900 shadow-xs rounded-[14px] p-6`}
    >
      <div className="flex items-start gap-1 mb-4">
        <Heading
          size="headingxs"
          as="h4"
          className="text-[28px] font-bold text-white-a700"
        >
          {nodeJsTitle}
        </Heading>
      </div>
      <div className="flex w-[90%] justify-center px-2">
        <Text
          as="p"
          className="flex-1 text-center text-[18px] font-normal leading-[30px] text-blue_gray-100"
        >
          {javascriptDescription}
        </Text>
      </div>
    </div>
  );
}
