import { Heading } from "../../components";
import UserInterfaceComponent from "../../components/UserInterfaceComponent";
import React from "react";

export default function PastProjectsSection() {
  return (
    <>
      <div className="flex flex-col items-center gap-5 px-14 md:px-5 sm:px-4">
        <div className="container-xs flex justify-center px-14 md:px-5 sm:px-4">
          <Heading
            as="h2"
            className="text-center text-[44.8px] font-bold leading-[71px] text-blue-500 lg:text-[37px] md:text-[28px] sm:text-[22px]"
          >
            Past Projects
          </Heading>
        </div>
        <UserInterfaceComponent />
      </div>
    </>
  );
}
