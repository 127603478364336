import { Helmet } from "react-helmet";
import AboutMeSection from "./AboutMeSection";
import PastProjectsSection from "./PastProjectsSection";
import React from "react";

export default function WdarkPage() {
  return (
    <>
      <Helmet>
        <title>Matin&#39;s Developer Profile</title>
        <meta 
          name="description" 
          content="Meet Matin, a versatile developer with a broad skill set spanning Discord bots, software applications, full-stack development, web development, and ethical hacking. Matin excels in C, C++, C#, Lua, JavaScript, and Rust, while also contributing to open-source projects, frameworks, and libraries. Explore his innovative work, including Roblox games, backend systems, and the PricePal app, which helps users compare prices at nearby stores." 
        />
      </Helmet>
      <div className="w-full bg-gray-900_01">
        <div className="mb-20 flex flex-col gap-[78px] max-w-[1920px] mx-auto bg-gray-900_01 lg:gap-[78px] md:gap-[58px] sm:gap-[39px]">
          {/* About me section */}
          <AboutMeSection />

          {/* Past projects section */}
          <PastProjectsSection />
        </div>
      </div>
    </>
  );
}
