import React from "react";

const sizes = {
  textxs: "text-[16px] font-normal not-italic lg:text-[13px]",
  texts: "text-[18px] font-normal not-italic lg:text-[15px]",
  textmd: "text-[19px] font-normal not-italic lg:text-[16px]",
};

const Text = ({ children, className = "", as, size = "textxs", ...restProps }) => {
  const Component = as || "p";

  return (
    <Component className={`text-gray-400 font-poppins ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };
