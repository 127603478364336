import React from "react";

const sizes = {
  headingxs: "text-[25px] font-bold lg:text-[21px] md:text-[23px] sm:text-[21px]",
  headings: "text-[28px] font-bold lg:text-[23px] md:text-[26px] sm:text-[24px]",
  headingmd: "text-[44px] font-bold lg:text-[37px] md:text-[40px] sm:text-[34px]",
  headinglg: "text-[56px] font-bold lg:text-[47px] md:text-[48px] sm:text-[42px]",
};

const Heading = ({ children, className = "", size = "headingmd", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`text-blue-500 font-poppins ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
